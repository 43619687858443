import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ResizeObserver} from '@juggle/resize-observer';

/** FIX Windows Edge issue */
if(!window.ResizeObserver){
  window.ResizeObserver = ResizeObserver
}

/** Redirct to https on live site */
const {location} = window
if (location.protocol !== 'https:' && location.href.indexOf('konfigurator.roomer-cube.de') > 0) {
  location.replace(`https:${location.href.substring(location.protocol.length)}`);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
