import React, { useEffect, useLayoutEffect, useRef } from 'react'
import style from './style.scss'
import classnames from 'classnames/bind'
import {useStore} from '../state'
import {RequestButton} from './RequestForm'
import {useHash} from './'

const cx = classnames.bind(style)

const CheckIcon = () => <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentcolor"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>


const ModelLink = ({children}) => {
    const [model] = useStore(state => [state.model])
    const isActive = model === children
    const updateHash = useHash()

    return <a className={isActive ? 'active':''} onClick={() => updateHash({model: children})} >
        {isActive ?  <CheckIcon/> : null}
        {children}
    </a>

}

const Switcher = () => {
    const [product] = useStore(state => [state.product])

    const updateHash = useHash()
    
    return <div className="switcher">
        <input id="indoor" type="radio" value="indoor" checked={product=='indoor'} onChange={() =>  updateHash({product:'indoor'})} name="product" />
        <label htmlFor="indoor">Indoor</label>
        <input id="outdoor" type="radio" value="outdoor" checked={product=='outdoor'}  onChange={() =>   updateHash({product:'outdoor'})} name="product" />
        <label htmlFor="outdoor">Outdoor</label>
        <span className="switch" />
    </div> 
}

const ModelsNav = () => {
    const [product] = useStore(state => [state.product])


    return <div className="models_nav">
        <strong>Modelle:</strong>
        <ul>
            {product === 'indoor' ? <li><ModelLink>s</ModelLink></li> : null}
            <li><ModelLink>m</ModelLink></li>
            <li><ModelLink>l</ModelLink></li>
            <li><ModelLink>xl</ModelLink></li>
        </ul>        
    </div>
}


const ThemeLink = ({value, children}) => {
    const [theme] = useStore(state => [state.theme, state.setTheme])
    const updateHash = useHash()

    const isActive = value === theme
    return (<a className={isActive ? 'active':''} 
        onClick={() => updateHash({theme: value})}>
            {children}
        </a>)

}

const ThemesNav = () => {
    return <div className="theme_nav">
        <ThemeLink value="light">Hell</ThemeLink>
        <ThemeLink value="dark">Dunkel</ThemeLink>
    </div>
}

const OuterPanelLink = ({value}) => {
    const [outerPanel] = useStore(state => [state.outer_panel])
    
    const updateHash = useHash()

    const isActive = value === outerPanel
    const pic = <div className={`pic pic_${value}`} />

    return <a className={isActive ? 'active':''} onClick={() => updateHash({outer_panel: value})}>
            {pic}
            <svg viewBox="0 0 256 256"><path d="M18.947 18.947C5.627 32.267 1 61.517 1 128s4.626 95.732 17.947 109.053C32.267 250.373 61.517 255 128 255s95.732-4.626 109.053-17.947C250.373 223.733 255 194.483 255 128s-4.626-95.732-17.947-109.053C223.733 5.627 194.483 1 128 1S32.268 5.626 18.947 18.947z" fill="transparent"></path></svg>    
            {isActive 
            ? <svg className="active" viewBox="0 0 256 256"><path d="M18.947 18.947C5.627 32.267 1 61.517 1 128s4.626 95.732 17.947 109.053C32.267 250.373 61.517 255 128 255s95.732-4.626 109.053-17.947C250.373 223.733 255 194.483 255 128s-4.626-95.732-17.947-109.053C223.733 5.627 194.483 1 128 1S32.268 5.626 18.947 18.947z"></path></svg>    
            : null 
            }
    </a>
}

const OuterPlaneNav = () => {

    const [theme, product] = useStore(state => [state.theme, state.product])

    return <div className={`outer_panel_nav outer_panel_${theme} outer_panel_${product}`}>
        <ul className="light">
            <li><OuterPanelLink value="white" /></li>
            <li><OuterPanelLink value="dark" /></li>
            <li><OuterPanelLink value="wood" /></li>              
            <li><OuterPanelLink value="image" /></li>         
            
        </ul>
        <ul className="dark">
            <li><OuterPanelLink value="white" /></li>
            <li><OuterPanelLink value="dark" /></li>
            <li><OuterPanelLink value="wood" /></li>            
            <li><OuterPanelLink value="image" /></li>
        </ul>
    </div>

}

const NotificationText = () => {

    return <div className="notification_text">
        <span className="asterics">*</span> Sie können auch ihre <strong>eigene</strong> Farbe/Logo bestimmen.
    </div>
}



export default () => {

    const {mode} = useStore()

    return <div className={cx("options", {hidden: mode !== 'options'})}>
        <Switcher />
        <ModelsNav />
        <ThemesNav />
        <OuterPlaneNav />
        <div className="grow" />
        <RequestButton />
    </div>

}


