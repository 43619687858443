import React, {Suspense, useEffect, useRef, useState} from 'react'
import {Canvas, useFrame} from '@react-three/fiber'
import IndoorModel, {Glass} from './Model/Indoor'
import OutdoorModel from './Model/Outdoor'
import {OrbitControls,  useHelper, PerspectiveCamera} from '@react-three/drei'
import Configurator from './Configurator'
import Layout from './Layout.jsx'
import {useStore, useView} from './state'
import * as THREE from 'three'
import {Html, Sky} from '@react-three/drei'
import { RoomerCubeLoadingIcon, RoomerCubePulsIcon } from './RoomerCubeLoadingIcon';
import Cloud from './Model/Clouds'

const Model = () => {
  const product = useStore(state => state.product)

  const indoorRef = useRef()
  const outdoorRef = useRef()

  const outdoorY = product === 'outdoor' ? 0 : 10
  const indoorY = product === 'indoor' ? 0 : -10
  const [loaded, setLoaded] = useState(false)


  useFrame(() => {
    indoorRef.current.position.y = THREE.MathUtils.lerp(indoorRef.current.position.y, indoorY, .05)
    outdoorRef.current.position.y = THREE.MathUtils.lerp(outdoorRef.current.position.y, outdoorY, .05)
  })

  useEffect(() => {

    const timer = window.setTimeout(() => setLoaded(true), 300)

    return () => window.clearTimeout(timer)

  }, [])
  
  return <><group ref={indoorRef} >
            {product === 'indoor' && loaded ? <Sky azimuth={1} inclination={0.1} distance={100} /> : null}
            <IndoorModel loaded={loaded} />
          </group>
          <group ref={outdoorRef} >
            {product === 'outdoor' ? <Sky azimuth={1} inclination={0.9} distance={100} /> : null}
            {loaded ? <OutdoorModel /> : null }

          </group>
          </>        
 }

const Scene = () => {

    const {isMobile} = useView()
    const [product, model] = useStore(({product, model}) => [product, model])

    const [reZoomed, setReZoomed] = useState(false)

    useEffect(() => setReZoomed(false), [product, model])


    const ocRef = useRef()
    const {pathname} = window.location
    const oc = <OrbitControls  ref={ocRef} target={[-5, -1.25, isMobile ? -8 : -2]}  enableDampening={true} enableDamping={true} enableZoom={true} enablePan={false} 
    maxPolarAngle={Math.PI/2}
    minPolarAngle={Math.PI/2}
    maxAzimuthAngle={Math.PI / 5}
    minAzimuthAngle={-Math.PI / 4}
    minDistance={8}
    maxDistance={28}

/>

    

    const lZoom = new THREE.Vector3(-5.13, -1.24, 10)
    const xlZoom = new THREE.Vector3(-2.65, -1.24, 20)
    const xlZoomDesktop = new THREE.Vector3(-2.65, -1.24, 10)

    useFrame(({camera}) => {
      
        if(isMobile) {
         
          if(product === 'outdoor' && model === 'xl' && !reZoomed){
            camera.position.lerp(xlZoom, .05) 
                        
            camera.updateProjectionMatrix()
            if(camera.position.z + .3 > xlZoom.z){
              setReZoomed(true)
            }
            
          }
          if(product === 'outdoor' && model === 'l' && !reZoomed) {
            camera.position.lerp(lZoom, .05) 
                        
            camera.updateProjectionMatrix()
            if(camera.position.z + .1 > lZoom.z){
              setReZoomed(true)            
            }
            
          }
        }
        else {
          if(product === 'outdoor' && model === 'xl' && camera.position.z < xlZoomDesktop.z && !reZoomed){

            
            camera.position.lerp(xlZoomDesktop, .05) 
            camera.updateProjectionMatrix()
          
            if(camera.position.z + .01 > xlZoomDesktop.z){
        
              setReZoomed(true)
            }
                                    
          }
        }
       
        
    })


    

    return <group position={[-2, -1.25, isMobile ? -8 : -2]} >
        <Model />
        {oc}
      </group>   
    
}


function HTMLLoading () {
  return (<Html>
      <RoomerCubeLoadingIcon />
  </Html>)
}
  


const indoorBgColor = new THREE.Color('#d8d8d8')
const outdoorBgColor = new THREE.Color('#e2eefb')
const ModelBody = () => {

  const colorRef = useRef()

  const product = useStore(({product}) => product)
  
  useFrame(() => {
    if(product === 'outdoor'){
      colorRef.current && colorRef.current.lerp(outdoorBgColor, .05)
    }else {
      colorRef.current && colorRef.current.lerp(indoorBgColor, .05)
    }

  })


  return <>
    <color ref={colorRef} attach="background" args={['#d8d8d8']} />   
     
    <Suspense fallback={<HTMLLoading />}>           
        <group position={[-3, 0, 0]}>

          <Scene />
            
        </group>
    </Suspense> 
    </>
}
  
const ConfiguratorModel = () => <Canvas dpr={[1,2]} camera={{fov:25}}>
       <ModelBody />
    </Canvas>


export default () => (<Layout>
        <div className="configurator_block">
                <div className="scene">
                    <ConfiguratorModel />
                </div>
                <div className="swipe_icon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentcolor"><g><rect fill="none" height="24" width="24" x="0"/></g><g><g><path d="M18.89,14.75l-4.09-2.04c-0.28-0.14-0.58-0.21-0.89-0.21H13v-6C13,5.67,12.33,5,11.5,5S10,5.67,10,6.5v10.74L6.75,16.5 c-0.33-0.07-0.68,0.03-0.92,0.28L5,17.62l4.54,4.79C9.92,22.79,10.68,23,11.21,23h6.16c1,0,1.84-0.73,1.98-1.72l0.63-4.46 C20.1,15.97,19.66,15.14,18.89,14.75z"/><path d="M20.13,3.87C18.69,2.17,15.6,1,12,1S5.31,2.17,3.87,3.87L2,2v5h5L4.93,4.93c1-1.29,3.7-2.43,7.07-2.43 s6.07,1.14,7.07,2.43L17,7h5V2L20.13,3.87z"/></g></g></svg>
                </div>
                <Configurator />
        </div>
    </Layout>)
