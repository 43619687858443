import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import './style.scss'
import Options from './Options'
import RequestForm from './RequestForm'
import { useStore } from '../state'


export default () => {
    return <div id="configrator">
            <Options />
            <RequestForm />
    </div>
}



export const hashToObject = () => {
    const {hash} = window.location
    const paars = hash.substr(1).split(';')
    return paars.reduce((acc, paar) => {
        const [key, value] = paar.split('=')

        return value ? {...acc, [key]: value} : acc
    }, {})
    
}

const defaultModel = {
    'indoor': 's',
    'outdoor': 'm'
}

const modelExists = ({product, model}) => 
    product === 'outdoor' && !['m', 'l', 'xl'].includes(model) ? false : true

export const useHash = () => {

    const {setProduct, setModel, setTheme, setOuterPanel, product, model, theme, outer_panel} = useStore()
   
    const updateHash = useCallback((params) => {
        
        
        const hashObject = { product, model, theme, outer_panel, ...hashToObject(), ...params}
        const validHashObject = {
            ...hashObject, 
            model: modelExists(hashObject) ? hashObject.model : defaultModel[hashObject.product]
        }

        setProduct(validHashObject.product)
        setModel(validHashObject.model)
        setTheme(validHashObject.theme)
        setOuterPanel(validHashObject.outer_panel)
        
        const hash = Object.keys(validHashObject).map(key => `${key}=${validHashObject[key]}`).join(';')
        window.location.hash = `#${hash}`
    
    }, [])

    
    

    return updateHash
}