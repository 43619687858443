import create from 'zustand'
import {hashToObject} from './Configurator'



export const useStore = create(set => ({
    mode: 'options',
    setMode: (mode) => set(state => ({...state, mode})),
    product: 'indoor',
    setProduct: (product) => set(state => ({...state, product})),
    model: 's',
    theme: 'light',
    outer_panel: 'white',
    auth_token: null,
    setModel: (model) => set(state => ({...state, model})),
    setTheme: (theme) => set(state => ({...state, theme, outer_panel: theme === 'dark' ? 'dark' : 'white'})),
    setOuterPanel: (outer_panel) => set(state => ({...state, outer_panel})),
    setAuthToken: (auth_token) => set(state => ({...state, auth_token})),
    additionalRequestData: {},
    updateAdditionalRequestData: (params) => set(state => ({...state, additionalRequestData: {...state.additionalRequestData, ...params}})),
    ...hashToObject()

}))

export const useView = create(set => ({
    isMobile: false,
    setMobile: (isMobile) => set(state => ({...state, isMobile}))
}))


export const getOuterPanels = () => {
    return ['white', 'dark', 'wood', 'image']
}

export const cubeModels = ['s', 'm', 'l', 'xl']