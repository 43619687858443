/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF, useTexture, useCubeTexture } from '@react-three/drei'
import {useStore} from '../state'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'


const glassParams = {
  color: 0xffffff,
  metalness: .15,
  roughness: 0,
  alphaTest: 0.1,
  depthWrite: false,
  transmission: 1,
  opacity: .3, // set material.opacity to 1 when material.transmission is non-zero
  transparent: true
}


const useModelTexture = (path) => {

  const texture = useTexture(path)
  texture.encoding = THREE.sRGBEncoding
  texture.decoding = THREE.sRGBEncoding
  texture.flipY = false
  return texture
} 



export default function Model(props) {
  const group = useRef()
  const groundRef = useRef()

  const { nodes } = useGLTF('/roomercube-outdoor.glb')

  const {theme, model, outer_panel} = useStore()




  const bakedCubeMHorizontTexture = useModelTexture(`/textures/outdoor/baked_cube_m_horizont.jpg`)
  

  const bakedCubeMTopButtomPanelTexture = useModelTexture(`/textures/outdoor/baked_cube_m_top_bottom_panel.jpg`)

  const bakedCubeHorizontLTexture = useModelTexture(`/textures/outdoor/baked_cube_l_horizont.jpg`)

  const bakedCubeBalkeTexture = useModelTexture(`/textures/outdoor/baked_cube_balke.jpg`)
  
  const bakedCubeLTopButtomPanelTexture = useModelTexture(`/textures/outdoor/baked_cube_l_top_bottom_panel.jpg`)
  
  const bakedCubeHorizontXLTexture = useModelTexture(`/textures/outdoor/baked_cube_xl_horizont.jpg`)

  const bakedCubeXLTopButtomPanelTexture = useModelTexture(`/textures/outdoor/baked_cube_xl_top_bottom_panel.jpg`)

  const bakedCubeMEntryTexture = useModelTexture(`/textures/outdoor/baked_cube_m_entry.jpg`)

  const bakedCubeLEntryTexture = useModelTexture(`/textures/outdoor/baked_cube_l_entry.jpg`)
  
  const bakedCubeXLEntryTexture = useModelTexture(`/textures/outdoor/baked_cube_xl_entry.jpg`)
  
  const bakedCubeMGroundAMTexture = useModelTexture(`/textures/outdoor/baked_cube_m_invert_ground.jpg`)
  const bakedCubeLGroundAMTexture = useModelTexture(`/textures/outdoor/baked_cube_l_invert_ground.jpg`)
  const bakedCubeXLGroundAMTexture = useModelTexture(`/textures/outdoor/baked_cube_xl_invert_ground.jpg`)
  
  const bakedCubeGroundTexture = useModelTexture(`/textures/outdoor/baked_cube_ground.jpg`)
  
  let groundAM = bakedCubeMGroundAMTexture

  const color = theme === 'light' ? 0xffffff : 0x666666
  if (model === 'l'){
    groundAM = bakedCubeLGroundAMTexture
  } else if (model === 'xl') {
    groundAM = bakedCubeXLGroundAMTexture
  }

  const envMap =  useCubeTexture(['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png'], {path:'/'})
  const glassMaterial =  <meshStandardMaterial {...glassParams} envMap={envMap} envMapIntensity={10} />



  const groundY = model === 'm' ? -1.2 : -1.35
  useFrame(() => {

  
    groundRef.current.position.y = THREE.MathUtils.lerp(groundRef.current.position.y, groundY, .3)


  })
  
  return (
    <group ref={group} {...props} dispose={null}>
       {model === 'm' && <>
          <mesh geometry={nodes.cube_m_plane_up_bottom.geometry}>
            <meshBasicMaterial map={bakedCubeMHorizontTexture} color={color} />
          </mesh>

          <mesh geometry={nodes.window_m.geometry} position={[0, 0, 0.22]} >
            {glassMaterial}
          </mesh>
          <mesh geometry={nodes.edge_m.geometry} position={[0, 0, 0.02]} >
            <meshBasicMaterial map={bakedCubeMEntryTexture} />
          </mesh>

          <mesh
            geometry={nodes.cube_m_top_bottom_inner_panel.geometry}
          >
            <meshBasicMaterial map={bakedCubeMTopButtomPanelTexture} color={color} />
          </mesh>

          <mesh geometry={nodes.cube_m_lamp_edge.geometry} position={[0, 1.12, 0]} >
            <meshBasicMaterial map={bakedCubeMTopButtomPanelTexture} color={color} />
          </mesh>
          <mesh geometry={nodes.cube_m_lamp_light.geometry} position={[0, 1.12, 0]} />

      </>
        }
        {model === 'l' && <>
          <mesh geometry={nodes.cube_l_plane.geometry}>
            <meshBasicMaterial map={bakedCubeHorizontLTexture} color={color} />
          </mesh>
          <mesh geometry={nodes.balke_l.geometry} rotation={[Math.PI / 2, 0, 0]} >
            <meshBasicMaterial map={bakedCubeBalkeTexture} color={color} />
          </mesh>
          <mesh geometry={nodes.balke_l.geometry} rotation={[Math.PI / 2, 0, 0]} position-x={2} >
            <meshBasicMaterial map={bakedCubeBalkeTexture} color={color} />
          </mesh>

          <mesh geometry={nodes.edge_l.geometry} position={[0, 0, 1.19]}>
            <meshBasicMaterial map={bakedCubeLEntryTexture} />
          </mesh>
          <mesh geometry={nodes.edge_l_door.geometry} position={[0, 0, 1.19]} >
            <meshBasicMaterial map={bakedCubeLEntryTexture} color={color} />
          </mesh>
      
          <mesh geometry={nodes.window_l.geometry} position={[0.8, 0, 0.92]}>   
            {glassMaterial}
          </mesh>
          <mesh geometry={nodes.cube_l_top_bottom_panel.geometry} >
            <meshBasicMaterial map={bakedCubeLTopButtomPanelTexture} color={color}/>
          </mesh>


          <mesh geometry={nodes.cube_l_lamp_edge.geometry}position={[-1.1, 1.12, 0]}>
            <meshBasicMaterial map={bakedCubeLTopButtomPanelTexture} color={color}/>
          </mesh>
      
          <mesh geometry={nodes.cube_l_lamp_light.geometry} position={[-1.1, 1.12, 0]} />
          </>
        }

      {model === 'xl' && <>
          <mesh geometry={nodes.cube_xl_plane.geometry}>
            <meshBasicMaterial map={bakedCubeHorizontXLTexture} color={color} />
          </mesh>
         
          <mesh geometry={nodes.balke_l.geometry} rotation={[Math.PI / 2, 0, 0]} position-x={-1} >
            <meshBasicMaterial map={bakedCubeBalkeTexture} color={color} />
          </mesh>
          <mesh geometry={nodes.balke_l.geometry} rotation={[Math.PI / 2, 0, 0]} position-x={1} >
            <meshBasicMaterial map={bakedCubeBalkeTexture} color={color} />
          </mesh>
          <mesh geometry={nodes.balke_l.geometry} rotation={[Math.PI / 2, 0, 0]} position-x={3} >
            <meshBasicMaterial map={bakedCubeBalkeTexture} color={color} />
          </mesh>
          <mesh geometry={nodes.cube_xl_top_bottom_panel.geometry} >
            <meshBasicMaterial map={bakedCubeXLTopButtomPanelTexture} color={color} />
          </mesh>

          <mesh geometry={nodes.edge_xl.geometry} position={[0, 0, 0]} >
            <meshBasicMaterial map={bakedCubeXLEntryTexture} />
          </mesh>
          <mesh geometry={nodes.edge_xl_door.geometry} position={[0, 0, 0]} >
            <meshBasicMaterial map={bakedCubeXLEntryTexture} color={color} />
          </mesh>
      
          <mesh geometry={nodes.window_xl.geometry} position={[0, 0, -0.01]}>
            {glassMaterial}
          </mesh>

          <mesh geometry={nodes.cube_xl_lamp_edge.geometry} position={[-2.1, 1.12, 0]}>
            <meshBasicMaterial map={bakedCubeXLTopButtomPanelTexture} color={color} />
          </mesh>
          <mesh geometry={nodes.cube_xl_lamp_light.geometry} position={[-2.1, 1.12, 0]} />

          </>
        }
        
      
        <CubeSides {...{nodes, color, outer_panel, model}} />

      

      <mesh ref={groundRef} geometry={nodes.ground_l.geometry} >
          <meshBasicMaterial  map={bakedCubeGroundTexture} alphaMap={groundAM} transparent={true} color={0x2d3e50}  />
      </mesh>
      
    </group>
  )
}


const CubeSides = ({nodes, color, model, outer_panel}) => {

  const leftSideRef = useRef()
  const rightSideRef = useRef()

  const leftSideOuterPanelRef = useRef()
  const rightSideOuterPanelRef = useRef()
    
  const bakedCubeSidesTexture = useModelTexture(`/textures/outdoor/baked_cube_sides.jpg`)
  

  const bakedCubeWallsTexture = useModelTexture(`/textures/outdoor/baked_cube_walls.jpg`)
  
  const bakedCubeWallsWoodTexture = useModelTexture(`/textures/outdoor/baked_cube_outer_panel_wood.jpg`)
  
  const bakedCubeWallsImageTexture = useModelTexture(`/textures/outdoor/baked_cube_outer_panel_image.jpg`)
  
  const outerPanelArgs = {
    map: outer_panel === 'wood' 
    ? bakedCubeWallsWoodTexture : outer_panel === 'image'
    ? bakedCubeWallsImageTexture: bakedCubeWallsTexture, 
    color: outer_panel === 'dark' ? 0x444444 : 0xffffff
  }

  let sideLeftPositionX = 0
  let sideRightPositionX = 0

  if (model === 'l'){
    sideLeftPositionX = -.8
    sideRightPositionX = .8
  } else if (model === 'xl') {
    sideLeftPositionX = -1.8
    sideRightPositionX = 1.8
  }



  useFrame(() => {

    leftSideRef.current.position.x = THREE.MathUtils.lerp(leftSideRef.current.position.x, sideLeftPositionX, .1)
    rightSideRef.current.position.x = THREE.MathUtils.lerp(rightSideRef.current.position.x, sideRightPositionX, .1)
    leftSideOuterPanelRef.current.position.x = THREE.MathUtils.lerp(leftSideOuterPanelRef.current.position.x, 0, .1)
    rightSideOuterPanelRef.current.position.x = THREE.MathUtils.lerp(rightSideOuterPanelRef.current.position.x, 0, .1)
  })

  useEffect(() => {
    
    leftSideOuterPanelRef.current.position.x -= .25
    rightSideOuterPanelRef.current.position.x += .25

  }, [outer_panel, model])
  
    return <>
      <group ref={leftSideRef} >
          <mesh geometry={nodes.cube_side_left_outer_panel.geometry} position-x={.01}>
            <meshStandardMaterial opacity={true} color={0xcccccc} opacity={.02} />
          </mesh>
          
          <mesh ref={leftSideOuterPanelRef} geometry={nodes.cube_side_left_outer_panel.geometry}>
              <meshBasicMaterial side={2} {...outerPanelArgs}  />
          </mesh>
          <mesh geometry={nodes.cube_side_left.geometry}  >
            <meshBasicMaterial  map={bakedCubeSidesTexture} color={color} />
          </mesh>        
          <mesh geometry={nodes.cube_side_left_inner_panel.geometry}>
            <meshBasicMaterial map={bakedCubeWallsTexture} color={color} />
          </mesh>
        </group>
      
      <group ref={rightSideRef}>
          <mesh geometry={nodes.cube_side_right_outer_panel.geometry} position-x={-.01}>
              <meshStandardMaterial opacity={true} color={0xcccccc} opacity={.02} />
          </mesh>
          <mesh  ref={rightSideOuterPanelRef}  geometry={nodes.cube_side_right_outer_panel.geometry} >
              <meshBasicMaterial side={2} {...outerPanelArgs} />
          </mesh>
          <mesh geometry={nodes.cube_side_right_inner_panel.geometry}>
            <meshBasicMaterial map={bakedCubeWallsTexture} color={color} />
          </mesh>
          <mesh geometry={nodes.cube_side_right.geometry} >
            <meshBasicMaterial map={bakedCubeSidesTexture} color={color} />
          </mesh>    
      </group>
      </>
}





useGLTF.preload('/roomercube-outdoor.glb')
