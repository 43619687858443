import React from 'react'

export default () => {


    return <svg version="1.1" className="ok-icon" xmlns="http://www.w3.org/2000/svg"x="0px" y="0px"
		viewBox="0 0 100 100" >
			
		<g>
			<circle className="circle" cx="50" cy="49.9999657" r="47.5"/>
			<circle className="circle-dash" cx="50" cy="49.9999657" r="47.5"/>
			<polyline className="check" points="28.6469955,52.0561066 42.2152748,65.6243896 71.3530045,36.4866562 	"/>
			<polyline className="check-dash" points="28.6469955,52.0561066 42.2152748,65.6243896 71.3530045,36.4866562 	"/>
		</g>
		</svg>

}