import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useStore} from '../state'
import style from './style.scss'
import classnames from 'classnames/bind'
import OkIcon from './OkIcon';

const cx = classnames.bind(style)


const sessionTokenEndpoint = process.env.REACT_APP_OFFER_REQUEST_SESSION_TOKEN_ENDPOINT
const sendmailEndpoint = process.env.REACT_APP_OFFER_REQUEST_ENDPOINT

const RightArrow = () => <svg width="49" height="8" viewBox="0 0 49 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M48.3536 4.35355C48.5488 4.15829 48.5488 3.84171 48.3536 3.64645L45.1716 0.464466C44.9763 0.269204 44.6597 0.269204 44.4645 0.464466C44.2692 0.659728 44.2692 0.976311 44.4645 1.17157L47.2929 4L44.4645 6.82843C44.2692 7.02369 44.2692 7.34027 44.4645 7.53553C44.6597 7.7308 44.9763 7.7308 45.1716 7.53553L48.3536 4.35355ZM0 4.5H48V3.5H0V4.5Z" fill="currentColor"/>
</svg>


export const  RequestButton = () => {

    const { setMode, setAuthToken} = useStore()
    useLayoutEffect(() => {
        fetch(sessionTokenEndpoint, {
            method: 'POST'
        })
        .then(res => res.text())
        .catch(err => console.error(err))
        .then((auth_token) => setAuthToken(auth_token))
    }, [])
    return <button className="RequestButton" onClick={() => setMode('request')}>
        Modell anfragen
        <RightArrow />
    </button>
}


export const  BackButton = () => {

    const { setMode} = useStore()
  
    return <button className="RequestBackButton" onClick={() => setMode('options')}>
        <RightArrow />
        zum Konfigurator
    </button>
}


const getThemeLabel = (theme) => theme === 'light' ? 'Hell': 'Dunkel'
const getProductLabel = (product) => product === 'indoor' ? 'Indoor' : 'Outdoor'

const ChosenOptions = () => {

    const { product, model, theme, outer_panel } = useStore()

    return <div className="chosen_options">
        <h4>Ihre Auswahl</h4>
        
        <ul>
            <li>
                <h5>Modell</h5>
                <div className="product_model">
                    <span>{getProductLabel(product)}</span>
                    <span>{model}</span>
                </div>
            </li>
            <li>
                <h5>Farbe</h5>
                <div className={`theme_outer_panel outer_panel_${product}`}>
                    <span>{getThemeLabel(theme)}</span>
                    <span className={`label pic_${outer_panel}`} />
                </div>
            </li>
        </ul>
        
        
    </div>
}

const AdditionalOptions = () => {

    const [product, data, update] = useStore(state => [state.product, state.additionalRequestData, state.updateAdditionalRequestData])

    const [addOns, setAddons] = useState({})

    const updateChecked = (e) => {
        
            setAddons({...addOns, [e.target.name] : e.target.checked})
        
    }

    useEffect(() => {
        const addons = Object.keys(addOns).filter(key => addOns[key]).join(', ')
        update({...data, addons})
    }, [addOns])

    
    return <div className="additional_options">
        <h4>Optionale Add-Ons</h4>
        
        <div className="checkbox_switch">
            <input type="checkbox" onChange={updateChecked} name="furniture" id="furniture"/>

            <label htmlFor="furniture">Möblierung</label>
        </div>
       
       <div className="checkbox_switch">
            <input type="checkbox" onChange={updateChecked} name="technique" id="technique" />
            <label htmlFor="technique">Konferenztechnik</label>    
        </div>
        
        {product === 'outdoor' && <div className="checkbox_switch">
            <input type="checkbox" onChange={updateChecked}  name="sanitary" id="sanitary" />
            <label htmlFor="sanitary">Sanitär-Vorinstallation</label>    
        </div>}
    </div>
}

const PrivacyCheck = () => {

    const [data, update] = useStore(state => [state.additionalRequestData, state.updateAdditionalRequestData])


    return <div className={cx("privacy_check", "checkbox_switch", {nonvalid: data.gdpr && !data.gdpr.valid})}>
            <input type="checkbox" name="gdpr" id="gdpr" onChange={(e) => update({gdpr: {valid : e.target.checked}})} />
            <label htmlFor="gdpr">Ich stimme der <a href="https://roomer-cube.de/datenschutz.html">Datenschutzerklärung</a> zu</label>
    </div>
}
function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const validatePhone = (phone) => {
    if(!phone) return false;
    
    const patterns = [
        /^(\+\d{1,3}\s?)?\((\d{1,4})\)[\s-]?(\d{3,4})[\s-]?(\d{2,4})[\s-]?(\d{2,4})[\s-]?(\d{2,4})?$/,
        /^(\+\d{1,4}\s?)?\d{1,4}[\s-]?\d{3,4}[\s-]?\d{2,4}[\s-]?\d{2,4}[\s-]?\d{2,4}?$/
    ];

    return patterns.some(pattern => phone.match(pattern));

}


const InputComment = () => {
    const [data, update] = useStore(state => [state.additionalRequestData, state.updateAdditionalRequestData])
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()

    const verifyEmail = () => {
        if(!validateEmail(email)){
            update({...data, email: { notifyError: true, valid: false} })
        }
        else {
            update({...data, email: { notifyError: false, valid: true, value: email} })            
        }
        
    } 
    const verifyPhone = () => {
        if(!validatePhone(phone)){
            update({...data, phone: { notifyError: true, valid: false} })
        }
        else {
            update({...data, phone: { notifyError: false, valid: true, value: phone} })            
        }
        
    } 
   
    const unNotify = () => {
        update({...data, email: { ...data.email, notifyError: false}, phone: { ...data.phone, notifyError: false} })            

    }

    const setComment = (comment) =>  update({...data, comment })            
   

    return <div className="input_comment">   
        
        <input 
            className={cx("contact_email", {nonvalid: data.email && data.email.notifyError})} 
            onBlur={verifyEmail} 
            onChange={(e) => setEmail(e.target.value)}
            onFocus={unNotify}             
            name="email"             
            placeholder="E-Mail-Adresse" />        
        <input 
            className={cx("contact_phone", {nonvalid: data.phone && data.phone.notifyError})} 
            onBlur={verifyPhone} 
            onChange={(e) => setPhone(e.target.value)}
            onFocus={unNotify} 
            name="phone"             
            placeholder="Telefonnummer" />        
        
        <textarea name="comment" onChange={(e) => setComment(e.target.value)} placeholder="Ihre Nachricht" />
    </div>
}

const LoadingSvg = () => <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
viewBox="0 0 100 100">
<circle fill="currentColor" stroke="none" cx="6" cy="50" r="6">
  <animate
    attributeName="opacity"
    dur="1s"
    values="0;1;0"
    repeatCount="indefinite"
    begin="0.1"/>    
</circle>
<circle fill="currentColor" stroke="none" cx="26" cy="50" r="6">
  <animate
    attributeName="opacity"
    dur="1s"
    values="0;1;0"
    repeatCount="indefinite" 
    begin="0.2"/>       
</circle>
<circle fill="currentColor" stroke="none" cx="46" cy="50" r="6">
  <animate
    attributeName="opacity"
    dur="1s"
    values="0;1;0"
    repeatCount="indefinite" 
    begin="0.3"/>     
</circle>
</svg>

const SendRequestButton = ({onSuccess, onFailure}) => {

    const [data, update, auth_token, chosenData] = useStore(({theme, product, outer_panel, model, ...state}) => [
        state.additionalRequestData, 
        state.updateAdditionalRequestData, 
        state.auth_token, 
        {
            theme, product, outer_panel, model
        }
    ])
    const [sending, setSending] = useState(false)

    const sendRequest = () => {

        if (!data.email || !data.email.valid) {
            update({email: {valid: false, notifyError: true}})
            return
        }
        if (!data.phone  || !data.phone.valid) {
            update({phone: {valid: false, notifyError: true}})
            return
        }

        if (!data.gdpr || !data.gdpr.valid) {
            update({gdpr: {valid: false}})
            return 
        }

      
        setSending(true)
    }    

    useEffect(() => {

        if(sending) {
            const body = {
                ...chosenData,
                email: data.email.value,
                phone: data.phone.value,
                comment: data.comment || '',
                addons: data.addons  || ''
            }
          
            fetch(sendmailEndpoint, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${auth_token}`,
                    'Content-type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            .then(res => res.ok ? onSuccess() : onFailure())            
        }
    }, [sending])
    
    return <button className="RequestSendButton" onClick={sendRequest}>
        Anfrage versenden
        {sending && <LoadingSvg />}
    </button> 
}


const RequestFormResult = ({requested}) => {

    return requested 
    ? <div className="success"><OkIcon /></div>
    : <div className="failure">opps... das versenden hat leider nicht gekplappt. Rufen Sie uns lieber gleich an.</div>

}


export default () => {

    const { mode } = useStore()

    const [requested, setRequested] = useState(null)


    return <div className={cx("request_form", {hidden: mode !== 'request', requested: requested !== null})}>
        {requested === null
        ? <> 
            <BackButton />
            <ChosenOptions />
            <AdditionalOptions />
        
            <InputComment />
            <PrivacyCheck />
            
            <SendRequestButton {...{onSuccess: () => setRequested(true), onFailure: () => setRequested(false)}} />       
         </>
        : <RequestFormResult  {...{requested}}/>
        }
    </div>
    
} 