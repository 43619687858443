import React from 'react'




const Header = () => (<header className="grid-x">
    <a className="small_logo" href="https://roomer-cube.de">
        <img src="https://roomer-cube.de/files/formfoundation-theme/images/logo/fofo-logo.svg" alt=""/>
    </a>
</header>)




const Footer = () => {
    return <div className="footer">
            
            <div className="footer-logo"><img  src="https://roomer-cube.de/files/formfoundation-theme/images/logo/fofo-logo-mb.svg" alt="Roomer-Cube"/></div>
            <div className="columns item small-12 medium-6 large-3">
                                    
                <h2 className="h-title">ADRESSE</h2>
                <p>ROOMER-CUBE by<br/>formfoundation GmbH</p>
                <p>Goerzallee 299<br/>14167 Berlin</p>
                <p><a href="tel:04930270006855">+49 (0)30 270 00 68 -55</a></p>
                <p><a href="mailto:info@roomer-cube.de">info@roomer-cube.de</a></p>
            </div>
           
            <div className="columns item small-12 medium-6 large-2">    
                    <h2 className="h-title">MENU</h2>
                
                    <ul className="footer_menu ">
                        <li>
                                <a className="h-title first last" href="https://roomer-cube.de/beispiele.html" title="RoomerCUBE Raumsystem Beispiele">
                                    BEISPIELE
                                </a>
                            </li>
                            <li>
                                <a className="h-title first last" href="https://roomer-cube.de/outdoor.html" title="RoomerCUBE Outdoor Raumsysteme">
                                    OUTDOOR
                                </a>
                            </li>
                            <li>
                                <a className="h-title first last" href="https://roomer-cube.de/indoor.html" title="RoomerCUBE Indoor Raumsysteme">
                                    INDOOR
                                </a>
                            </li>
                        <li >
                                <a className="h-title first last" href="https://roomer-cube.de/kontakt.html" title="RoomerCUBE Hersteller Kontakt">
                                Kontakt
                            </a>
                        </li>
                        <li >
                            <a className="first" href="https://roomer-cube.de/impressum.html" title="RoomerCUBE Impressum">
                                Impressum
                            </a>
                        </li>
                        <li >
                            <a className="" href="https://roomer-cube.de/datenschutz.html" title="RoomerCUBE Datenschutz">
                                Datenschutz
                            </a>
                        </li>
                        <li >
                            <a className="last" href="https://roomer-cube.de/agb.html" title="RoomerCUBE AGB">
                                AGB
                            </a>
                        </li>
                    </ul>
                </div>
    </div>
}

export default ({children}) => {


    return <>
        <Header />
            {children}
        <Footer />
    </>
}