import React, { useRef, useEffect, useState, useCallback } from 'react'
import {useFrame, useThree} from '@react-three/fiber'
import { useGLTF, useTexture, useCubeTexture } from '@react-three/drei'
import * as THREE from 'three'
import { useStore, getOuterPanels, useView } from '../state'

const glassParams = {
  color: 0xffffff,
  metalness: .15,
  roughness: 0,
  alphaTest: 0.1,
  depthWrite: false,
  transmission: 1,
  opacity: .3, // set material.opacity to 1 when material.transmission is non-zero
  transparent: true
}


const getThemeTexture = (model) =>
   `/textures/baked_${model}_4096.jpg`


const getOuterPanelLightTexture = (outerPanel, model) => {
  switch(outerPanel){
    case 'wood':
    case 'image':
      return `/textures/baked_${model}_outer_panel_${outerPanel}.jpg`
    default:
      return `/textures/baked_${model}_outer_panel_white.jpg`
  }
}

const getOuterPanelTexture = (theme, outerPanel, model) => 
  //theme === 'dark'
  //? getOuterPanelDarkTexture(outerPanel, model)
  getOuterPanelLightTexture(outerPanel, model)



const usePreloadTextures = () => {

  const models = ['s', 'm', 'l', 'xl']

  models.forEach( m => {
      
    useTexture.preload(`/textures/baked_${m}_4096.jpg`)
    useTexture.preload(`/textures/baked_${m}_scene.jpg`);
      
    getOuterPanels().forEach(outerPanel =>  {
      useTexture.preload(getOuterPanelLightTexture(outerPanel, m))
    })   

  })

  return null
}


const useOuterPanelTexture = (...args) => {
  const texture = useTexture(getOuterPanelTexture(...args))
  texture.flipY = false
  texture.encoding = THREE.sRGBEncoding
  texture.decoding = THREE.sRGBEncoding
  return texture
}

const useCorpusTexture = (model) => {
  const texture = useTexture(`/textures/baked_${model}_4096.jpg`)
  texture.flipY = false
  texture.encoding = THREE.sRGBEncoding
  texture.decoding = THREE.sRGBEncoding
  return texture
}

const useScene = (model) => {
  const bakedSceneTexture = useTexture(`/textures/baked_${model}_scene.jpg`)
  bakedSceneTexture.flipY = false
  bakedSceneTexture.encoding = THREE.sRGBEncoding
  bakedSceneTexture.decoding = THREE.sRGBEncoding

  return bakedSceneTexture

}

export default function Model({loaded, ...props}) {
  const group = useRef()
  const { nodes } = useGLTF('/roomer-cube-lowpoly.glb')
  const [positionX, setPositionX] = useState(-0.01)

  const {model, theme} = useStore()
  const {isMobile, setMobile} = useView()

  usePreloadTextures()

  useThree(({size}) => {
    if(size.width < 460){
      !isMobile && setMobile(true)
    }
  })

  useEffect(() => {
    switch(model){
      case 's':
        return setPositionX(-0.01)
      case 'm':
        return setPositionX(-9)
      case 'l':
          return setPositionX(-17.28)
      case 'xl':
        return setPositionX(-26.14)
    }
  }, [model])
  

  
  const envMap =  useCubeTexture(['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png'], {path:'/'})
  const glassMesh =  <meshStandardMaterial {...glassParams} envMap={envMap} envMapIntensity={10} />


  useFrame(() => {

    if(!group.current) return
    
    group.current.position.z = THREE.MathUtils.lerp(
      group.current.position.z,
      0,
      0.075 - Math.abs(50) / 2000
    )

    group.current.rotation.y = THREE.MathUtils.lerp(
      group.current.rotation.y,
      0,
      0.01 - Math.abs(Math.PI/2) / 2000
    )
   
    group.current.position.x = THREE.MathUtils.lerp(
      group.current.position.x,
      positionX,
      0.05
    ) 
  })
  const cubeColor = theme === 'dark' ? 0x666666 : 0xffffff
  
  const _model = (
    <group ref={group} {...props} position-z={-50} rotation-y={Math.PI/2} dispose={null}>
      <group position={[-0.01, 0.03, -0.19]}>       
        <mesh
          geometry={nodes.glass_s_front.geometry}          
          rotation={[Math.PI, 0, Math.PI]}
        >
          {glassMesh}
        </mesh>
      </group>
      <group position={[8.98, 0.03, 0]}>
       
        <mesh
          geometry={nodes.glass_m_front001.geometry}
          position={[0.24, 0, 0]}
          rotation={[Math.PI, 0, Math.PI]}
          >
          {glassMesh}
        </mesh>
        <mesh
          geometry={nodes.glass_m_front002.geometry}
          position={[-0.24, 0, 0]}
          rotation={[0, 0, -Math.PI]}
          >
          {glassMesh}
        </mesh>
      </group>
      <group position={[17.28, 0.03, 0]}>
        <mesh
          geometry={nodes.glass_l_front001.geometry}
          position={[0, 0, 0]}
          rotation={[0, 0, -Math.PI]}
          >
          {glassMesh}
        </mesh>
        <mesh
          geometry={nodes.glass_l_front002.geometry}
          position={[0, 0, 0.14]}
          rotation={[Math.PI, 0, Math.PI]}
          >
          {glassMesh}
        </mesh>
      </group>
      <group position={[26.51, 0.03, 0]}>
        <mesh
          geometry={nodes.glass_xl_front001.geometry}
          position={[-0.37, 0, 0.31]}
          rotation={[0, 0, -Math.PI]}
          >
          {glassMesh}
        </mesh>
        <mesh
          geometry={nodes.glass_xl_front002.geometry}
          position={[-0.37, 0, 0.45]}
          rotation={[Math.PI, 0, Math.PI]}
          >
          {glassMesh}
        </mesh>
      </group>
      <mesh
        geometry={nodes.s_lamp_light.geometry}
        position={[-0.01, 1.07, -0.19]}
        rotation={[Math.PI, 0, Math.PI]}
      />
      <mesh geometry={nodes.m_lamp_light.geometry}  position={[8.98, 1.05, 0]} />
      <mesh geometry={nodes.l_lamp_light.geometry}  position={[17.28, 1.05, 0]} />
      <mesh
        geometry={nodes.xl_lamp_light.geometry}
        position={[26.14, 1.05, 0]}
      />
      <mesh
        geometry={nodes.scene_s_back.geometry}
        position={[-0.11, -1.12, -2.72]}
        rotation={[Math.PI, 0, Math.PI]}
        >
        <meshBasicMaterial map={useScene('s')} />
      </mesh>
      <mesh
        geometry={nodes.scene_m_back.geometry}
        position={[-0.11, -1.12, -2.72]}
        rotation={[Math.PI, 0, Math.PI]}
        >
        <meshBasicMaterial map={useScene('m')} />
      </mesh>
      <mesh
        geometry={nodes.scene_l_back.geometry}
        position={[-0.11, -1.12, -2.72]}
        rotation={[Math.PI, 0, Math.PI]}
      >
        <meshBasicMaterial map={useScene('l')} />
      </mesh>
      <mesh
        geometry={nodes.scene_xl_back.geometry}
        position={[-0.11, -1.12, -2.72]}
        rotation={[Math.PI, 0, Math.PI]}
      >
        <meshBasicMaterial map={useScene('xl')} />
      </mesh>
      
      
      <OuterPanels nodes={nodes} />
    
      
      <mesh
        geometry={nodes.cube_s.geometry}
        position={[-0.01, 0.03, -0.19]}
        rotation={[Math.PI, 0, Math.PI]}
      >
          <meshBasicMaterial map={useCorpusTexture('s')} color={cubeColor} />
        </mesh>
      <mesh
        geometry={nodes.cube_m.geometry}
        position={[8.98, 0.03, 0]}
        rotation={[Math.PI, 0, Math.PI]}
      >
        <meshBasicMaterial map={useCorpusTexture('m')} color={cubeColor} />
      </mesh>
       <mesh
        geometry={nodes.cube_l.geometry}
        position={[17.28, 0.03, 0]}
        rotation={[Math.PI, 0, Math.PI]}
      >
        <meshBasicMaterial map={useCorpusTexture('l')} color={cubeColor} />
      </mesh>
      <mesh
        geometry={nodes.cube_xl.geometry}
        position={[26.14, 0.03, 0]}
        rotation={[Math.PI, 0, Math.PI]}
      >
        <meshBasicMaterial map={useCorpusTexture('xl')} color={cubeColor} />
      </mesh>
     
    </group>
  )

  return loaded ? _model : null

}

const OuterPanels = ({nodes}) => {
  const {model, theme, outer_panel} = useStore()
  const [outerPanelTrace, setOuterPanelTrace] = useState({})

  const colorArgs = ['dark', 'white'].includes(outer_panel) 
  ? {color: outer_panel === 'dark' ? 0x666666 : 0xffffff}
  : {}

  const refs = {
    s: {
      left: useRef(),
      right: useRef()
    },
    m: {
      left: useRef(),
      right: useRef()
    },
    l: {
      left: useRef(),
      right: useRef()
    },
    xl: {
      left: useRef(),
      right: useRef()
    }
  }

  
  useEffect(() => {
    const {left, right} = refs[model]
    const currentTrace = outerPanelTrace[model] && {
      left: {
        to: outerPanelTrace[model].left.to
      },
      right: {
        to: outerPanelTrace[model].right.to
      }
    } || {
      left: {
        to: left.current.position.x
      },
      right: {
        to: right.current.position.x
      }
    }
    
    const changeTrace = {[model] : {
      left : {
        from: left.current.position.x + .05,
        to: currentTrace.left.to
      },
      right: {
        from: right.current.position.x - .05,
        to: currentTrace.right.to
      }
    }}

    setOuterPanelTrace({
      ...outerPanelTrace, 
      ...changeTrace
      })

      left.current.position.x = changeTrace[model].left.from
      right.current.position.x = changeTrace[model].right.from
  }, [model, outer_panel])

 
  useFrame(() => {
    
    if(outerPanelTrace[model] && refs[model].left.current && refs[model].right.current) {
      refs[model].left.current.position.x = THREE.MathUtils.lerp(
        refs[model].left.current.position.x,
        outerPanelTrace[model].left.to,
        0.1
      ) 
      refs[model].right.current.position.x = THREE.MathUtils.lerp(
        refs[model].right.current.position.x,
        outerPanelTrace[model].right.to,
        0.1
      ) 
    }
  })

  
  return <>
    <mesh
        geometry={nodes.cube_s_outer_panel_left.geometry}
        position={[-0.01, 0.03, -0.19]}
        rotation={[Math.PI, 0, Math.PI]}
        ref={refs.s.left}
      >
          <meshBasicMaterial map={useOuterPanelTexture(theme, outer_panel, 's')} {...colorArgs}  />
      </mesh>
      <mesh
        geometry={nodes.cube_s_outer_panel_right.geometry}
        position={[-0.01, 0.03, -0.19]}
        rotation={[Math.PI, 0, Math.PI]}
        ref={refs.s.right}
      >
          <meshBasicMaterial map={useOuterPanelTexture(theme, outer_panel, 's')}  {...colorArgs}  />
      </mesh>      
      <mesh
        geometry={nodes.cube_m_outer_panel_left.geometry}
        position={[8.98, 0.03, 0]}
        rotation={[Math.PI, 0, Math.PI]}
        ref={refs.m.left}

        >
        <meshBasicMaterial map={useOuterPanelTexture(theme, outer_panel, 'm')}  {...colorArgs} />
     </mesh>      
      <mesh
        geometry={nodes.cube_m_outer_panel_right.geometry}
        position={[8.98, 0.03, 0]}
        rotation={[Math.PI, 0, Math.PI]}
        ref={refs.m.right}

        >
        <meshBasicMaterial map={useOuterPanelTexture(theme, outer_panel, 'm')}  {...colorArgs}  />
      </mesh>      
      <mesh
        geometry={nodes.cube_l_outer_panel_left.geometry}
        position={[17.28, 0.03, 0]}
        rotation={[Math.PI, 0, Math.PI]}
        ref={refs.l.left}

        >
        <meshBasicMaterial map={useOuterPanelTexture(theme, outer_panel, 'l')} {...colorArgs}  />
      </mesh>      
      <mesh
        geometry={nodes.cube_l_outer_panel_right.geometry}
        position={[17.28, 0.03, 0]}
        rotation={[Math.PI, 0, Math.PI]}
        ref={refs.l.right}

        >
        <meshBasicMaterial map={useOuterPanelTexture(theme, outer_panel, 'l')}  {...colorArgs}  />
      </mesh>      
      <mesh
        geometry={nodes.cube_xl_outer_panel_left.geometry}
        position={[26.14, 0.03, 0]}
        rotation={[Math.PI, 0, Math.PI]}
        ref={refs.xl.left}

        >
        <meshBasicMaterial map={useOuterPanelTexture(theme, outer_panel, 'xl')}  {...colorArgs}  />
      </mesh>          
      <mesh
        geometry={nodes.cube_xl_outer_panel_right.geometry}
        position={[26.14, 0.03, 0]}
        rotation={[Math.PI, 0, Math.PI]}
        ref={refs.xl.right}
        >
        <meshBasicMaterial map={useOuterPanelTexture(theme, outer_panel, 'xl')}  {...colorArgs}  />
      </mesh>       
      </>

}




useGLTF.preload('/roomer-cube-lowpoly.glb')

